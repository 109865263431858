import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
// import "./bootstrap";
import router from "./router";
import store from "./store";
import Vuetify from "vuetify";
import Notifications from "vue-notification";
import { isLoggedIn, validateSession } from "./utils/auth";
import vueVimeoPlayer from "vue-vimeo-player";
import VueTour from "vue-tour";
import VueHtml2Canvas from "vue-html2canvas";
// import Particles from "particles.vue";

require("vue-tour/dist/vue-tour.css");


// Vue.use(Particles);
// import {VideoPlayer} from 'vue-videojs7'

// Vue.use(VideoPlayer);

// GOOGLE

import VueGapi from "vue-gapi";

Vue.use(Notifications);
Vue.use(vueVimeoPlayer);
Vue.use(VueTour);
Vue.use(VueHtml2Canvas);
Vue.use(Vuetify);

// GOOGLE

Vue.use(VueGapi, {
  clientId:
    "705545961295-sli4gice1d24p6opdetb065f4d0na7ps.apps.googleusercontent.com",
  scope: "profile email",
});

import VueVideoPlayer from "vue-video-player";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";
import "videojs-flash";
import "videojs-contrib-hls/dist/videojs-contrib-hls";
Vue.use(VueVideoPlayer);

Vue.config.productionTip = false;

router.beforeEach(async (to, from, next) => {
  if (!to.matched.some((record) => record.meta.isPublic) && !isLoggedIn()) {
    next({
      path: "/unauthorized",
      // query: { redirect: to.fullPath },
    });
  } else {
    const nearestWithTitle = to.matched
      .slice()
      .reverse()
      .find((record) => record.meta && record.meta.title);

    await validateSession();

    if (nearestWithTitle) {
      document.title = to.meta.title;
    }
    if (
      to.meta.role != store.getters.getInfoUser &&
      !to.name == "unauthorized"
    ) {
      next({
        path: "/unauthorized",
      });
    }
    next();
  }
});

import es from "vuetify/es5/locale/es";

new Vue({
  router,
  store,
  vuetify: new Vuetify({
    lang: {
      locales: { es },
      current: "es",
    },
  }),
  render: (h) => h(App),
}).$mount("#app");
